import React from "react";
import SummaryBlock from "../components/Home/SummaryBlock";
import SEO from "../components/seo";
import styled from "styled-components";
import Layout from "../components/layout";
import Accordion from "../components/Reusables/Accordion";
import AwardAccordion from "../components/About/AwardAcordion";
import HeroImages from "../components/Reusables/HeroImages";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .human-resources {
    color: black;
    text-decoration: underline;
  }

  .job-description {
    color: rgba(0, 0, 0, 0.54);
  }

  .uni-links {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 50px;
    color: black;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 30px;
    }
  }
`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Vacancies />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/vacancies-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};
const Vacancies = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("vacancies", fetchData);
  const { data: heroImage } = useQuery("vacancyImages", fetchHeroImage);

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "VacanciesPage".toLowerCase() &&
              item.Image !== null && <HeroImages heroImage={item.Image.url} />
          )}
        {newData && newData.showSummary && newData.summary && (
          <SummaryBlock summary={newData.summary} />
        )}
        {newData && newData.showAccordion && (
          <div className="container-fluid">
            <Accordion
              accordionHeading={newData.AccordionHeading}
              accordion={newData.Accordion}
            />
            <div className="padding-right-8 padding-left-8 pt-5">
              {newData && newData.VacancieAccor.length > 0 && (
                <AwardAccordion accordion={newData.VacancieAccor} />
              )}
            </div>
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};
