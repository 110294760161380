import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import DescriptionBlock from "../Home/DescriptionBlock"

const StyledWrapper = styled.div`
  .heading,
  .subtext {
    letter-spacing: ${props => props.theme["zero"]};
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .heading {
    color: ${props => props.theme["primaryColor"]} !important;
    font-size: ${props => props.theme["subHeadingSize"]} !important;
    font-weight: ${props => props.theme["headingBold"]} !important;
    line-height: ${props => props.theme["h2LineHeight"]} !important;
    text-transform: uppercase;

    @media (max-width: 600px) {
      font-size: ${props => props.theme["FooterLinkSize"]} !important;
      line-height: unset !important;
    }
  }

  .sub-heading-light {
    font-family: ${props => props.theme["primaryFont"]} !important;
    @media (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  .subtext {
    font-size: 20px !important;
    font-weight: ${props => props.theme["textBold"]} !important;
    line-height: ${props => props.theme["subTextLineHeight"]} !important;

    @media (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  .accordion-tab {
    background-color: ${props => props.theme["GreyBackgroundColor"]};
    border-bottom: 1px solid #c8c8c8 !important;
    box-shadow: none;
  }

  .award-img {
    max-width: 200px;
    @media (max-width: 600px) {
      max-width: 100px;
    }
  }

  .MuiIconButton-label {
    color: #464fd0;
    font-size: 42px;
  }

  .MuiAccordionSummary-root {
    padding: 0px !important;
  }

  .here-link {
    color: black;
    text-decoration: underline;
  }

  .react-desc a {
    text-decoration: underline;
    color: black;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function AwardAccordion({ accordion }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const mainUrl = process.env.GATSBY_API_URL;

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <StyledWrapper>
      <div className={classes.root}>
        {React.Children.toArray(
          accordion.map((item, index) => (
            <Accordion
              className="accordion-tab"
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={expanded === index ? "-" : "+"}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography
                  className={classes.heading + " image-head-accordion"}
                >
                  {item.image && (
                    <img
                      className="img-fluid award-img mr-4"
                      src={mainUrl + item.image.url}
                      alt=""
                    />
                  )}
                </Typography>
                <Typography
                  className={
                    classes.secondaryHeading +
                    " heading sub-heading-light d-flex flex-column justify-content-center"
                  }
                >
                  {item.shortDescription}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.secondaryHeading + " subtext"}>
                  <div className="react-desc">
                    {/* <ReactMarkdown
                      linkTarget="_blank"
                      children={item.description}
                    /> */}
                    <DescriptionBlock description={item.description}  />
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </div>
    </StyledWrapper>
  );
}
